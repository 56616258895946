<template>
	<div>
		<FlightHeader
			v-if="!trip.loading && !trip.error && tripItemData"
			class="flight-header"

			:tripID="trip_id"
			:flightName="trip.tripItems[trip_item_id].name"
			:flightStartDate="trip.tripItems[trip_item_id].data ? trip.tripItems[trip_item_id].data.outbound.departure : ''"
			:flightEndDate="trip.tripItems[trip_item_id].data.return ? trip.tripItems[trip_item_id].data.return.arrival : trip.tripItems[trip_item_id].data.outbound.arrival"
		/>
		<!-- version of header when we are lacking data -->
		<FlightHeader
			v-else-if="!trip.loading && !trip.error"
			class="flight-header"

			:tripID="trip_id"
		/>

		<Loader :loading="trip.loading" :error="trip.error" @refresh="refresh"/>

		<div class="outer-wrapper">
			<div v-if="!trip.loading && !trip.error">
				<div class="inner-wrapper" v-if="tripItem.alerts.all.length > 0">

					<div class="notification-action" :class="tripItem.alerts.all[0].alert_code">
						<div class="title">{{ tripItem.alerts.all[0].main_message }}</div>
						<div class="text" v-html="tripItem.alerts.all[0].description"></div>
						<ResultIcon class="icon" type="neutral" :message="tripItem.alerts.all[0].secondary_message" />

						<div v-if="tripItem.alerts.all[0].alert_type == 'traveller_validation'">
							<button class="button main" @click="alertAction('traveller_validation')">Edit Travellers</button>
						</div>
						<div v-else-if="tripItem.alerts.all[0].alert_type == 'expiry'">
							<button class="button main" @click="alertAction('expiry')">Search</button>
						</div>
						<div v-else-if="tripItem.alerts.all[0].alert_type == 'price'">
							<button class="button main" @click="alertAction('price')">Confirm</button>
							<div class="or">or</div>
							<div class="other">Select New Flight</div>
							<button class="button" @click="alertAction('expiry')">Search</button>
						</div>
						<div v-else-if="tripItem.alerts.all[0].alert_type == 'fare'">
							<button class="button" @click="alertAction('fare')">Continue</button>
						</div>
						<div v-else>
							<!-- <div class="btn" @click="alertAction()">Continue</div> -->
						</div>

						<div v-if="status.code == 'not_booked' && status.paid == 0">
							<div class="or">or</div>
							<div class="remove-item" @click="removeFromTrip()">Remove Flight</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="!trip.loading && !trip.error && tripItemData">

				<div class="inner-wrapper" v-if="status.code == 'booked'">

					<div class="res-no">
						Reservation (PNR) No:
						<span class="no">{{ tripItem.bookingStatus.PNR }}</span>
						<!-- Your internal booking reference number is:
						<span class="no">{{ tripItem.bookingStatus.bookingID }}</span> -->
					</div>

					<div class="notification-action green">
						<div class="title">Booking is successful</div>
						<div class="text">Your flight booking is successful. Have a nice flight!</div>
						<ResultIcon class="icon" type="success" :message="'Booked!'" />
					</div>

					<div class="e-ticket" v-if="tripItem.data.tickets.length > 0">
						<div class="heading">E-Ticket(s)</div>
						<div v-for="(ticket, index) in tripItem.data.tickets" :key="index" class="ticket">
							<div :let="traveller = tripItem.travellers.find(traveller => traveller.traveller_id == ticket.travelerId)">Traveller: {{ traveller.data.firstName }} {{ traveller.data.lastName }}</div>
							<!-- <div>Traveller Type: {{ ticket.travelerType }}</div> -->
							<div>Document Number: <strong>{{ ticket.documentNumber }}</strong></div>
							<!-- <div>Document Status: {{ ticket.documentStatus }} (no need to display?)</div>
							<div>Price: £{{ ticket.price_details.total }}</div> -->

							<!-- <ul v-for="fare in ticket.fare_details" :key="fare.segmentId">
								<li>
									<div class="heading6">{{ tripItem.bookedData.segments[fare.segmentId].departure.iataName }} ({{ tripItem.bookedData.segments[fare.segmentId].departure.iataCode }}) -> {{ tripItem.bookedData.segments[fare.segmentId].arrival.iataName }} ({{ tripItem.bookedData.segments[fare.segmentId].arrival.iataCode }})</div>
									<div>Cabin: {{ fare.cabin }}</div>
									<div>Class: {{ fare.class }}</div>
									<div>
										Included Checked Bags: {{ fare.includedCheckedBags.weight }}{{ fare.includedCheckedBags.weightUnit }}
										<span v-if="fare.includedCheckedBags.quantity">(x{{ fare.includedCheckedBags.quantity }})</span>
									</div>
									<div>Meals Services: <span v-for="(meal, index) in fare.mealServices" :key="index">{{ meal.label }}</span> (this could be multiple things, e.g. meal, snack)</div>
								</li>
							</ul> -->
						</div>
					</div>
				</div>
			</div>

			<div class="inner-wrapper main-content" v-if="!trip.loading && !trip.error && tripItemData">
				<div class="pax-container" v-if="status.code == 'not_booked'">
					<div class="pax_select">
						<div class="heading">
							Passengers <img v-if="status.code == 'not_booked'" class="edit-section" @click="manageTravellers()" src="@/assets/travellers/edit-travellers.png" alt="Edit Passengers Icon" />
						</div>
						<span class="passenger" v-for="(pax) in tripItem.travellers" :key="pax.id"> <!-- each of these becomes a circular pax icon thing. For now, put generic icon instead of profile pic -->
							<TravellerIcon
								:name="pax.data.firstName"
								:lastName="pax.data.lastName"
								:type="pax.type"
								@click="status.code == 'not_booked' ? editPax(pax.passenger_id) : () => {}"
							/>
						</span>
						<span class="passenger" v-if="status.code == 'not_booked'">
							<AddTravellerIcon @click="manageTravellers()"/>
						</span>
					</div>
				</div>
				<div class="saved-flights" v-if="tripItemData">
					<div class="heading">
						Flight(s)
					</div>
					<div class="flights-date">
						{{ formatDate(tripItem.date) }}
					</div>
					<FlightCard
						class="flight-card"
						:name="tripItem.name"
						:origin="tripItemData.outbound.origin"
						:destination="tripItemData.outbound.destination"
						:layoversTotal="tripItemData.outbound.layoversTotal"
						:layoverLocations="tripItemData.outbound.layoverLocations"
						:arrivalDisplay="tripItemData.outbound.arrivalDisplay"
						:departureDisplay="tripItemData.outbound.departureDisplay"
						:travelTime="tripItemData.outbound.travelTime"
						:flightsData="tripItemData.outbound.flightsArray"
						:booked="tripItem.bookingStatus.code == 'booked'"
						:co2="tripItemData.outbound.totalCo2"
						:allFlightsFlown="tripItemData.outbound.allFlightsFlown"

						loadOpen="true"
					/>
					<div v-if="tripItemData.return">
						<div class="flights-date">
							{{ formatDate(tripItemData.return.departureDisplay) }}
						</div>
						<FlightCard
							class="flight-card"
							:name="tripItem.name"
							:origin="tripItemData.return.origin"
							:destination="tripItemData.return.destination"
							:layoversTotal="tripItemData.return.layoversTotal"
							:layoverLocations="tripItemData.return.layoverLocations"
							:arrivalDisplay="tripItemData.return.arrivalDisplay"
							:departureDisplay="tripItemData.return.departureDisplay"
							:travelTime="tripItemData.return.travelTime"
							:flightsData="tripItemData.return.flightsArray"
							:booked="tripItem.bookingStatus.code == 'booked'"
							:co2="tripItemData.return.totalCo2"
							:allFlightsFlown="tripItemData.return.allFlightsFlown"
							
							loadOpen="true"
						/>
					</div>
				</div>
				<div class="fares-container" ref="fares" v-if="status.code == 'not_booked'">
					<!-- Selected fare -->
					<div v-if="tripItemData.fare && !editFare && !fareLoading">
						<div class="heading">
							Selected Fare
							<img v-if="status.code == 'not_booked'" class="edit-section" @click="editFare = true" src="@/assets/flights-details/edit-fare.png" alt="Edit Fare Icon" />
						</div>

						<div class="fares" v-if="!fareLoading">
							<FareCard
								:fareKey = tripItemData.fare.key
								:fareName = tripItemData.fare.name
								:cabinClass = tripItemData.fare.cabin_class
								:origin = tripItemData.origin
								:destination = tripItemData.destination
								:baggage = tripItemData.fare.baggage
								:cancellationPolicy = tripItemData.fare.cancellation_policy
								:changePolicy = tripItemData.fare.change_policy
								:amenities = tripItemData.fare.description
								:descriptionMap = tripItemData.fare.description_map
								:price = tripItemData.fare.price

								:selected = true
								@changeFare="editFare = true"
							/>
						</div>
					</div>

					<!-- All fare options -->
					<div v-else-if="editFare && !fareLoading && tripItemData.fareOptions">
						<div class="heading">
							Select a Fare
						</div>
						<div class="fares" v-for="(fare, index) in tripItemData.fareOptions" :key="index">
							<!-- <pre>{{ fare }}</pre> -->
							<FareCard
								:fareKey = fare.key
								:fareName = fare.name
								:cabinClass = fare.cabin_class
								:origin = tripItemData.origin
								:destination = tripItemData.destination
								:baggage = fare.baggage
								:cancellationPolicy = fare.cancellation_policy
								:changePolicy = fare.change_policy
								:amenities = fare.description
								:descriptionMap = fare.description_map
								:price = fare.price

								:selectable = true
								@selected="selectFare(fare.key)"
							/>
						</div>
					</div>
					<div v-else-if="fareLoading">
						<div class="heading">
							Selected Fare
						</div>
						<div class="fares">
							<div class="card">
								<Loader :loading="fareLoading" />
							</div>
						</div>
					</div>
					<div v-else>
						<!-- Issue loading fare data. This could be because the flight is expired, and so there are no fare options -->
					</div>
				</div>
				<div class="carbon-section" v-if="tripItem.totalCo2">
					<div class="carbon" :style="{'background-image': 'url(' + getImage(carbon.img) + ')'}">
						<span>
							Your carbon footprint for this flight is:
						</span><br>
						<span class="total">
							{{ tripItem.totalCo2 }}kg CO<span class="unit">2</span>
						</span>
					</div>

					<!-- <div class="points">
						You could earn <span>x points</span> by booking this flight!
						Off set your Carbon Footprint for <span>£XX.XX</span>
					</div> -->
				</div>
			</div>

			<div v-if="!trip.loading && !trip.error && tripItemData">
				<TripItemsSummary 
					v-if="!editFare"
					:tripID="trip.tripID"
					:groupedTripItems="{ 'Summary': {
							items: [ tripItem ],
							totalPrice: tripItem.price,
							heading: 'Summary'
						}
					}"
					
					:showBookButton="true"
					@book="goToPayment()"
				/>
			</div>

			<div class="inner-wrapper" v-if="!trip.loading && !trip.error && tripItemData">
				<div class="not_booked_wrapper" v-if="status.code == 'not_booked' && status.totalPaid == 0">
					<button v-if="!editFare" class="button edit" @click="goToPayment()">Book Flights</button>
					<button v-else class="button save disabled" disabled>Book Flights</button>

					<div class="remove-item" @click="removeFromTrip()">Remove from the trip</div>
				</div>

				<button class="button" @click="viewTrip()">View Trip</button>
				<div class="error" style="color: red" v-if="validationError">{{ validationError }}</div>
				<!-- <button class="button share">Share</button> -->
			</div>
			<!-- If there is not data, trip item is broken etc - just show remove button -->
			<!-- <div class="not_booked_wrapper" v-if="!trip.loading && (trip.error || !tripItemData)">
				<div>Something went wrong loading this flight.</div>
				<div class="remove-item" @click="removeFromTrip()">Remove from the trip</div>
			</div> -->
		</div>
	</div>
</template>
<style scoped>
	.outer-wrapper {
		padding-bottom: 60px;
	}
	.inner-wrapper {
		padding: 0 10px;
	}
	.flight-header {
		margin-bottom: 20px;
	}
	.edit-section {
		float: right;
		color: #118AB2;
		cursor: pointer;
	}
	.back-icon, .upload {
		position: unset;
		margin: 44px 0px 0px 25px;
	}
	.upload {
		float: right;
		margin: 40px 25px 0px 0px;
	}
	.upload img {
		width: 20px;
	}
	.flights.details {
		padding: 0px;
		position: relative;
		box-sizing: border-box;
		margin: 0 auto;
		margin-bottom: 125px;
	}
	.image-holder {
		position: relative;
		background-repeat: no-repeat;
		min-height: 250px;
		margin-bottom: 30px;
		background-size: cover;
		background-position: center;
	}

	.results-wrapper {
		margin: 8px;
		width: 350px;
	}

	.heading {
		font-size: 20px;
		font-weight: 700;
		color: #118AB2;
		margin: 30px 0px 16px 0px;
	}
	.saved-flights .flights-date {
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 10px;
		color: black;
	}
	.saved-flights .flight-card {
		margin-bottom: 20px;
	}

	/* fares */
	.fares-container .edit-section {
		width: 32px;
	}
	.fares {
		margin: 0 auto;
		margin-bottom: 54px;
	}
	.fares .card {
		margin: 0 auto;
	}

	
	/* pax */
	.add-pax {
		position: relative;
	}
	.add-pax-img {
		height: 62px;
		width: 62px;
	}
	.add-pax img.plus {
		position: absolute;
		top: 17px;
		left: 17px;
	}
	.add_pax_btn {
		position: absolute;
		left: 10px;
		bottom: -20px;
		text-align: center;
		width: 40px;
		font-size: 12px;
		color: #C4C4C4;
		background-color: transparent
	}
	button.plus {
		background-color: transparent;
	}

	.pax-container .edit-section {
		width: 28px;
	}

	.passenger {
		display: inline-block;
		vertical-align: top;
		margin-bottom: 5px;
	}
	.passenger .pax {
		margin-right: 10px;
	}

	img.icon {
		position: absolute;
		top: 21px;
		right: 40px;
	}
	/* end pax */

	.button {
		display: block;
		margin: 0 auto;
		margin-top: 18px;
		text-decoration: none;
	}

	.button.edit {
		background-color: #06D6A0;
		margin-top: 22px;
	}
	.button.share {
		background-color: #118AB2;
	}

	.booking_status {
		border: 1px solid;
		display: inline-block;
		padding: 2px 5px;
		border-radius: 8px;

		float: right;
		font-weight: bold;

		background-color: white;
	}
	.booking_status.not_booked {
		border-color: red;
		color: red;
	}
	.booking_status.booked {
		border-color: green;
		color: green;
	}

	.not_booked_wrapper {
		text-align: center;
	}
	.remove-item {
		font-weight: 400;
		font-size: 12px;
		color: #50555C;

		background-image: url("../../../assets/trip/remove-icon.png");
		background-repeat: no-repeat;
		background-size: 16px 16px;
		background-position: right;

		display: inline-block;
		padding-right: 25px;
		line-height: 16px;
		margin: 0 auto;
		margin-top: 15px;

		cursor: pointer;
	}

	.e-ticket {
		font-size: 14px;
	}
	.e-ticket .ticket {
		margin-bottom: 10px;
	}

</style>
<script>
	import { mapState, mapActions, mapGetters } from 'vuex';
	import router from '@/router';
	import helpers from '@/helpers/helpers.js';
	import FlightCard from "@/components/flights/FlightCard.vue";
	import Menu from '@/components/Menu.vue'
	import Loader from '@/components/Loader.vue';
	import TravellerIcon from '@/components/travellers/TravellerIcon.vue';
	import AddTravellerIcon from '@/components/travellers/AddTravellerIcon.vue';
	import ResultIcon from '@/components/ResultIcon.vue';
	import FareCard from '@/components/flights/FareCard.vue';
	import FlightHeader from "@/components/flights/FlightHeader.vue";
	import TripItemsSummary from "@/components/trips/TripItemsSummary.vue";

	export default {
		name: 'savedFlightDetails',
		props: ['flight'],
		data() {
			return {
				carbon: {
					img: "flights-details/carbon-forest-bg",
				},
				plane_img: {
					img: "trip/plane-image",
				},

				trip_id: this.$route.params.trip_id ? this.$route.params.trip_id : null,
				trip_item_id: this.$route.params.trip_item_id ? this.$route.params.trip_item_id : null,

				// Booking validation
				validationError: false,

				// Fares
				editFare: false,
				fareLoading: false,

				// segments
				segments: [],
				travellers: [],
			}
		},
		components: {
			FlightCard,
			Menu,
			Loader,
			TravellerIcon,
			AddTravellerIcon,
			ResultIcon,
			FareCard,
			FlightHeader,
			TripItemsSummary
		},
		computed: {
			...mapGetters({
				tripItemBookingValidity: "trip/tripItemBookingValidity",
				tripItemTravellersValidity: "trip/tripItemTravellersValidity",
			}),
			...mapState({
				trip: state => state.trip,
			}),
			tripItem() { // Generic data for trip item (consistent for all trip item types)
				return this.trip.tripItems[this.trip_item_id];
			},
			tripItemData() { // Data only relevant for flights
				return this.trip.tripItems[this.trip_item_id].data;
			},
			// fare() {
			// 	return this.trip.tripItems[this.trip_item_id].data.fare;
			// },
			status() {
				return this.trip.tripItems[this.trip_item_id].bookingStatus;
			},
		},
		methods: {
			...mapActions({
				prefillSearch: "flightsSearch/prefillSearchValues",
				initTrip: "trip/initTrip",
				updateFare: "trip/updateFare",
				alertSuccess: "alert/success",
				alertError: "alert/error",
			}),
			formatPrice: (currency, to, howmany) => helpers.formatPrice(currency, to, howmany),
			selectFare(fareKey) {
				this.fareLoading = true;
				let fare = this.tripItemData.fareOptions.find(item => item.key == fareKey);
				this.updateFare({ tripID: this.trip_id, tripItemID: this.trip_item_id, fareKey: fareKey, price: fare.price.amount })
					.then(result => {
						this.alertSuccess("Fare updated successfully");
						this.editFare = false;
						this.fareLoading = false;
					},
					error => {
						this.alertError(error);
						this.editFare = false;
						this.fareLoading = false;
					});
			},
			getImage: (img) => helpers.getImage(img),
			formatDate: (date) => helpers.formatDate(date),
			editPax(traveller_id) {
				router.push({
					name: "Edit Traveller",
					params: {
						trip_id: this.trip_id,
						trip_item_id: this.trip_item_id,
						traveller_id: traveller_id,
					},
					query: {
						redirect: window.location.pathname
					}
				});
			},
			manageTravellers() {
				router.push({
					name: "Manage Trip Item Travellers",
					params: {
						trip_id: this.trip_id,
						trip_item_id: this.trip_item_id,
					},
				});
			},
			goToPayment() {
				if(this.status.code == 'not_booked') {
					// If validation not met, alert user.
					if(!this.tripItemBookingValidity(this.trip_item_id).valid) { 
						this.alertError(this.tripItemBookingValidity(this.trip_item_id).error);
					} else if(!this.tripItemTravellersValidity(this.trip_item_id).valid) {  // redirect to travellers screen if there is pax management that needs to be done.
						// Go to the pax screen
						router.push({
							name: "Checkout Manage Trip Item Travellers",
							params: {
								trip_id: this.trip_id,
								trip_item_id: this.trip_item_id,
							},
						});
					} else {
						// Otherwise Successful validation - go to book
						router.push({
							name: "Review",
							params: {
								trip_id: this.trip_id,
								trip_item_id: this.trip_item_id
							}
						})
					}
				}
			},
			removeFromTrip() {
				if(this.status.code == 'not_booked' && this.status.totalPaid == 0) {
					// Navigate to confirmation page
					router.push({
						name: 'Remove Trip Item',
						params: {
							trip_id: this.trip_id,
							trip_item_id: this.trip_item_id
						}
					})
				}
			},
			viewTrip() {
				router.push({ name: 'Trip', params: { trip_id: this.trip_id }})
			},
			alertAction(type) {
				if(type == 'traveller_validation') {
					// Go to the edit travellers page
					this.manageTravellers();
				} else if(type == 'price') {
					// Price confirmation page
					router.push({
						name: 'Confirm Flight Price',
						params: {
							trip_id: this.trip_id,
							trip_item_id: this.trip_item_id
						}
					})
				} else if(type == 'expiry') {
					// Search page (with required remove query params)
					this.prefillSearch({ tripItemID: this.trip_item_id });

					router.push({
						name: 'Flights Search',
						query: {
							trip_id: this.trip_id,
							trip_item_id: this.trip_item_id,
						}
					})
				} else if(type == 'fare') {
					// Scroll to fare options
					var element = this.$refs.fares;
					window.scrollTo({
						top: element.offsetTop,
						behavior: 'smooth'
					});
				}
			},
			async refresh() {
				await this.initTrip({ tripID: this.trip_id, tripItemID: this.trip_item_id });

				if(this.tripItemData.bookingStatus) {
					if(!this.tripItemData.fare && this.tripItemData.bookingStatus.code != 'booked') {
						this.editFare = true;
					}
				}
				
			}
		},
		async created() {
			this.$emit('update:topLayoutSettings', {
				left: {
					type: 'back',
					color: 'white',
					route: { name: 'Trip', params: { trip_id: this.trip_id } },
				},
				right: {
					type: 'none',
				},
				overlayContent: true,
			});
			
			await this.refresh();
		}
	}
</script>

